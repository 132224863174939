import React from 'react';

const IconLogo = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="100" height="100" rx="3" fill="#00BD90" />
    <path
      d="M40.9898 68.2L43.0849 61.2398H55.0878L57.183 68.2H67.8364L55.8335 31.8364H42.3392L30.3364 68.2H40.9898ZM45.3222 53.8534L48.9443 41.8506H49.2284L52.8506 53.8534H45.3222Z"
      fill="white"
    />
  </svg>
);

export default IconLogo;
